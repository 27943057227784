import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import { Link } from "gatsby"

const AgreementPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <div className="terms-of-card-page">
        <div className="contents">
            <h3>
                バルコカード 利用者の保護について
            </h3>
            <p className="lead">
              下記の店舗では、資金決済法第14条第1項に基づき、チャージ残高について基準日未使用残高（毎年3月31日・9月30日時点における未使用残高で、資金決済法3条第2項に定めるところにより算出したものをいいます。）の2分の1以上の金額を供託しています。<br />
              お客さまは、この供託金（発行保証金）から、チャージに係る債権に関して、店舗に対する他の債権者よりも優先して弁済を受ける権利があります。ただし、この場合であっても、お客さまが保有する未使用分のチャージについて全額保全されることを保証するものではありません。
            </p>
            <h4>
                対象店舗
            </h4>
            <p className="lead fw-b">
                北海道
            </p>
            <p className="store-list">
                <Link to="/baluko-nishiokasanjo">西岡3条</Link>／
                <Link to="/baluko-sapporoyamanote">札幌山の手</Link>／
                <Link to="/baluko-sapporonishimachi">札幌西町</Link>／
                <Link to="/baluko-hiragishi8jo">平岸8条</Link>／
                <Link to="/baluko-teinemaeda">手稲前田</Link>／
                <Link to="/baluko-hassamu8jo">発寒8条</Link>
            </p>
            <p className="lead fw-b">
                東北
            </p>
            <p className="store-list">
                <Link to="/baluko-sendaimiyamachi">仙台宮町</Link>
            </p>
            <p className="lead fw-b">
                東京都
            </p>
            <p className="store-list">
                <Link to="/baluko-yoga">用賀</Link>／
                <Link to="/baluko-hachiojikitano">八王子北野</Link>／
                <Link to="/baluko-yoyogisanchome">代々木3丁目</Link>／
                <Link to="/baluko-kamearinichome">亀有2丁目</Link>／
                <Link to="/baluko-nakamurabashi">中村橋</Link>／
                <Link to="/baluko-shinotsuka">新大塚</Link>／
                <Link to="/baluko-oyamakanaicho">大山金井町</Link>／
                <Link to="/baluko-kotosenda">江東千田</Link>／
                <Link to="/baluko-yahiro">八広</Link>／
                <Link to="/baluko-minowabashi">三ノ輪橋</Link>／
                <Link to="/baluko-nishiwaseda">西早稲田</Link>／
                <Link to="/baluko-ryogokuyonchome">両国4丁目</Link>／
                <Link to="/baluko-hongotodaimae">本郷東大前</Link>
            </p>
            <p className="lead fw-b">
                関東
            </p>
            <p className="store-list">
                <Link to="/baluko-ichikawahirata">市川平田</Link>／
                <Link to="/baluko-aeontownmoroyama">イオンタウン毛呂山</Link>／
                <Link to="/baluko-honatsugi">本厚木</Link>／
                <Link to="/baluko-ishioka">石岡</Link>／
                <Link to="/baluko-musashiurawa">武蔵浦和</Link>／
                <Link to="/baluko-seya">瀬谷</Link>
            </p>
            <p className="lead fw-b">
                近畿
            </p>
            <p className="store-list">
                <Link to="/baluko-sumatakatori">須磨鷹取</Link>／
                <Link to="/baluko-nanbamotomachi">難波元町</Link>／
                <Link to="/baluko-konanyamate">甲南山手</Link>／
                <Link to="/baluko-toayamate">トア山手</Link>／
                <Link to="/baluko-nishitanabe">西田辺</Link>／
                <Link to="/baluko-oyashikidori">御屋敷通</Link>
            </p>
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => <Seo title="バルコカード 利用者の保護について" description="" />
export default AgreementPage

